import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M0 6400 l0 -6400 6400 0 6400 0 0 6400 0 6400 -6400 0 -6400 0 0
-6400z m6797 4175 c594 -48 1143 -214 1648 -498 98 -55 261 -154 285 -174 8
-6 58 -42 110 -79 52 -37 97 -70 100 -74 3 -3 25 -21 50 -40 25 -19 47 -37 50
-40 3 -3 39 -34 80 -70 179 -154 393 -386 556 -600 333 -437 591 -995 710
-1535 109 -495 123 -1087 39 -1585 -79 -464 -245 -934 -462 -1308 -24 -40 -43
-77 -43 -81 0 -5 -4 -11 -8 -13 -4 -1 -26 -32 -47 -68 -55 -91 -193 -284 -289
-404 -150 -187 -437 -467 -626 -611 -36 -27 -71 -54 -77 -61 -7 -7 -29 -22
-50 -35 -21 -13 -40 -26 -43 -29 -9 -12 -205 -137 -295 -189 -119 -69 -363
-188 -500 -244 -312 -127 -634 -208 -1065 -269 -129 -18 -775 -18 -920 0 -408
51 -847 167 -1165 309 -94 42 -335 161 -350 172 -5 5 -37 23 -70 41 -233 127
-605 415 -804 620 -123 127 -201 214 -268 297 -441 545 -712 1131 -843 1822
-43 226 -54 376 -54 726 -1 458 21 649 118 1035 120 475 306 875 617 1330 24
35 159 210 169 220 3 3 30 34 60 70 70 84 293 308 390 391 41 35 77 66 80 69
23 24 183 145 283 213 166 114 253 166 452 268 664 341 1410 486 2182 424z"/>
<path d="M6150 10550 c-395 -38 -682 -100 -1045 -228 -397 -140 -752 -334
-1091 -597 -833 -646 -1376 -1594 -1515 -2648 -25 -194 -36 -602 -20 -817 11
-160 55 -451 91 -600 218 -909 724 -1693 1460 -2261 194 -150 393 -275 615
-387 293 -147 421 -197 715 -280 228 -65 335 -87 640 -133 113 -16 611 -23
785 -10 216 16 562 77 745 132 302 91 431 140 660 251 210 101 298 151 480
271 306 203 592 458 839 747 77 90 288 376 321 437 9 15 30 51 48 79 51 82 87
149 166 309 551 1123 542 2438 -23 3555 -103 202 -276 482 -375 604 -23 28
-48 60 -56 72 -97 139 -399 445 -610 617 -221 181 -471 343 -735 476 -394 200
-886 345 -1340 397 -164 18 -616 27 -755 14z m2299 -1007 c-8 -10 -57 -65
-109 -122 -52 -58 -124 -136 -159 -175 -35 -39 -106 -116 -157 -171 -51 -55
-108 -118 -126 -139 -18 -22 -46 -53 -63 -71 -57 -61 -275 -299 -330 -361 -5
-6 -53 -57 -105 -114 -52 -57 -101 -112 -109 -123 -7 -10 -28 -33 -46 -51 -18
-17 -111 -116 -206 -221 -95 -104 -210 -230 -255 -280 -46 -49 -93 -101 -104
-115 -12 -14 -38 -43 -58 -65 -20 -22 -80 -87 -132 -145 -52 -57 -110 -120
-128 -140 l-33 -35 -22 26 c-28 34 -59 75 -214 284 -70 94 -167 225 -217 292
-50 67 -98 131 -106 144 -8 12 -24 34 -36 48 -58 72 -245 334 -241 337 3 3 42
-15 88 -39 267 -140 687 -347 704 -347 16 0 115 66 185 123 8 7 31 23 50 37
19 13 37 27 40 30 3 3 21 17 40 30 19 14 46 35 60 47 14 13 28 23 32 23 3 0
13 6 20 13 7 7 29 24 48 37 19 13 46 35 60 47 14 13 33 26 43 29 9 3 17 10 17
15 0 5 7 9 15 9 8 0 15 4 15 8 0 4 12 15 28 24 15 9 38 26 52 37 14 11 66 50
115 87 50 37 92 71 93 75 2 5 8 9 13 9 9 0 85 54 99 71 3 3 28 20 55 39 28 19
52 36 55 40 3 3 75 57 160 120 85 63 160 118 165 122 14 12 114 86 315 235
197 146 196 145 309 231 86 65 103 74 80 45z m-3453 -4654 c3 -6 -9 -34 -29
-62 -19 -29 -62 -96 -97 -149 -59 -92 -64 -98 -96 -98 -19 0 -34 2 -34 4 0 11
183 292 198 304 20 15 49 16 58 1z m262 -13 c2 -10 -7 -29 -19 -42 -32 -34
-16 -56 48 -70 64 -14 69 -18 81 -53 19 -55 -31 -117 -110 -136 -25 -6 -28 -5
-28 19 0 18 8 30 25 38 27 12 42 45 28 59 -5 5 -31 13 -58 18 -82 15 -108 72
-63 137 36 52 89 69 96 30z m120 8 c28 -20 28 -28 -4 -49 -22 -14 -30 -15 -50
-4 -26 13 -31 38 -12 57 16 16 39 15 66 -4z m174 -1 c8 -10 23 -33 34 -50 18
-32 18 -33 -1 -48 -11 -8 -26 -14 -34 -12 -18 3 -74 103 -66 117 10 16 51 11
67 -7z m232 9 c5 -8 -13 -29 -104 -130 -44 -48 -80 -125 -80 -170 0 -12 -55
-21 -70 -12 -8 5 -7 24 5 64 25 86 176 256 227 256 9 0 19 -4 22 -8z m142 -6
c5 -14 -61 -126 -149 -252 -31 -45 -43 -54 -68 -54 -16 0 -29 4 -29 10 0 13
156 259 183 288 23 25 55 29 63 8z m312 -12 c2 -16 -4 -24 -26 -32 -28 -9 -31
-17 -51 -102 -19 -85 -20 -94 -5 -104 9 -7 15 -21 12 -32 -3 -16 -13 -20 -61
-22 -49 -3 -57 -1 -57 15 1 26 64 275 74 291 5 9 25 12 59 10 44 -3 52 -6 55
-24z m420 -7 c3 -24 0 -27 -27 -27 -23 0 -31 5 -31 18 0 46 53 55 58 9z m125
26 c3 -5 -12 -75 -32 -158 l-37 -150 -38 -3 c-44 -4 -43 -18 -8 140 25 110 42
161 56 170 14 10 54 10 59 1z m102 -23 c0 -21 -5 -26 -32 -28 -37 -4 -43 11
-16 40 23 25 48 19 48 -12z m122 14 c3 -9 -10 -79 -29 -157 -34 -137 -36 -142
-62 -145 -44 -5 -57 4 -47 30 5 13 22 77 37 143 15 66 32 126 37 133 13 17 56
15 64 -4z m179 8 c7 -4 8 -15 3 -29 -7 -21 -15 -23 -72 -23 -35 0 -67 3 -70 7
-4 3 3 17 15 30 16 18 32 23 67 23 25 0 51 -4 57 -8z m171 -9 c4 -10 -2 -22
-18 -34 -40 -30 -51 -46 -65 -86 -18 -51 -18 -108 1 -123 35 -29 2 -75 -44
-59 -90 31 -54 235 53 300 38 23 65 24 73 2z m116 3 c25 -19 21 -33 -12 -51
-27 -13 -31 -13 -51 3 -20 16 -21 21 -9 40 15 25 45 28 72 8z m153 -1 c6 -15
-5 -67 -48 -227 l-20 -78 -40 0 c-37 0 -40 2 -34 23 4 12 21 78 38 147 17 69
38 131 46 138 22 17 51 15 58 -3z m207 -20 c-1 -16 -17 -86 -35 -155 l-33
-125 -39 -3 -38 -3 7 33 c4 18 20 81 36 141 16 59 29 114 29 121 0 16 23 25
55 23 16 -2 20 -8 18 -32z m-1476 10 c65 -46 15 -237 -74 -279 -42 -20 -73
-21 -73 -2 0 27 12 46 29 46 42 0 89 151 57 183 -16 16 -21 48 -9 60 11 12 48
8 70 -8z m-1317 -145 c6 -41 15 -85 20 -97 15 -37 2 -54 -39 -51 l-36 3 -12
75 c-6 41 -12 83 -12 93 -1 21 49 71 60 60 5 -5 13 -42 19 -83z m920 87 c0 -2
9 -53 20 -113 11 -60 20 -112 20 -116 0 -5 -18 -8 -39 -8 l-39 0 -12 68 c-6
37 -14 77 -16 88 -4 15 3 32 23 53 24 27 43 39 43 28z m1143 -63 c13 -13 -14
-44 -38 -44 -27 0 -39 14 -31 35 5 15 57 21 69 9z m585 -16 c-2 -15 -10 -23
-23 -23 -13 0 -21 8 -23 23 -3 18 1 22 23 22 22 0 26 -4 23 -22z m-2750 -106
c3 -17 -1 -22 -21 -22 -31 0 -38 9 -26 32 13 25 43 19 47 -10z m932 3 c0 -18
-5 -25 -19 -25 -24 0 -33 13 -25 34 10 27 44 20 44 -9z m-695 1 c17 -12 18
-18 7 -40 -13 -30 -22 -32 -62 -11 -36 19 -37 29 -8 49 28 20 39 20 63 2z
m2250 -6 c18 -19 17 -20 -30 -41 -50 -22 -70 -17 -63 17 3 18 41 44 64 44 6 0
19 -9 29 -20z m-303 -6 c3 -3 4 -14 1 -25 -5 -16 -14 -19 -69 -19 -62 0 -64 1
-64 25 0 24 2 25 63 25 35 0 66 -3 69 -6z m-2854 -230 c2 -16 -4 -24 -25 -31
-25 -9 -30 -20 -52 -103 l-24 -94 21 -14 c17 -11 19 -18 11 -33 -8 -15 -21
-19 -65 -19 l-56 0 4 33 c6 40 65 264 73 278 4 5 30 9 58 7 44 -3 52 -6 55
-24z m135 -17 c39 -48 -1 -191 -66 -241 -45 -34 -92 -40 -92 -11 0 11 9 24 20
29 33 14 53 49 66 114 12 59 12 63 -9 82 -18 17 -20 24 -11 44 10 21 15 23 43
15 17 -5 39 -19 49 -32z m160 31 c4 -14 2 -21 -41 -193 l-28 -110 -38 -3 c-38
-3 -38 -3 -33 30 5 39 65 267 73 279 8 14 62 11 67 -3z m177 -11 c0 -15 -9
-29 -26 -38 -30 -15 -57 -72 -67 -136 -6 -35 -4 -45 13 -61 20 -19 19 -49 -3
-63 -31 -21 -97 39 -97 87 0 36 41 150 64 175 49 56 116 76 116 36z m115 11
c32 -14 32 -35 1 -49 -51 -24 -99 14 -64 49 15 15 30 15 63 0z m189 4 c4 -7
-6 -54 -53 -237 -18 -70 -18 -70 -54 -73 -23 -2 -37 1 -37 8 0 17 58 255 70
285 7 19 17 25 39 25 16 0 32 -4 35 -8z m111 -22 c0 -18 -6 -26 -23 -28 -13
-2 -25 3 -28 12 -10 26 4 48 28 44 17 -2 23 -10 23 -28z m125 8 c0 -13 -15
-84 -34 -158 l-34 -135 -36 -3 c-42 -4 -43 2 -20 93 25 98 35 138 41 170 8 42
21 55 54 55 23 0 29 -4 29 -22z m108 -4 c2 -15 -4 -23 -20 -28 -37 -10 -48 -7
-48 12 0 43 62 57 68 16z m166 18 c4 -7 -87 -157 -162 -264 -27 -39 -39 -48
-62 -48 -17 0 -30 4 -30 10 0 11 97 165 159 253 31 43 47 57 65 57 14 0 27 -4
30 -8z m260 -1 c3 -4 -8 -59 -24 -122 -55 -209 -46 -191 -88 -187 -27 2 -36 7
-34 18 16 66 74 285 78 292 7 11 61 10 68 -1z m466 -13 c0 -12 -10 -32 -22
-45 -30 -32 -15 -55 40 -65 54 -9 75 -21 92 -52 10 -19 11 -34 4 -53 -12 -35
-72 -81 -109 -85 -25 -2 -31 1 -33 19 -2 17 3 26 20 32 13 5 30 16 38 25 19
24 -6 46 -66 57 -91 17 -110 81 -46 149 44 45 82 54 82 18z m115 10 c34 -15
32 -34 -6 -53 -29 -15 -32 -15 -50 1 -22 20 -24 35 -7 52 15 15 30 15 63 0z
m177 7 c3 -3 -12 -74 -33 -157 -22 -84 -39 -153 -39 -155 0 -2 -16 -3 -35 -3
-26 0 -35 4 -35 17 0 29 62 277 72 291 10 12 60 17 70 7z m178 -19 c0 -29 -16
-36 -86 -36 -56 0 -62 6 -44 41 9 15 22 19 70 19 57 0 60 -1 60 -24z m165 -6
c0 -17 -7 -26 -25 -30 -20 -5 -27 -15 -34 -49 -8 -36 -7 -46 7 -56 24 -17 21
-31 -12 -54 -18 -13 -32 -35 -38 -58 -8 -35 -12 -38 -46 -41 -22 -2 -37 1 -37
8 0 6 16 78 36 161 l37 150 56 -3 c52 -3 56 -5 56 -28z m149 -32 c13 -37 0
-73 -40 -112 -38 -37 -39 -40 -18 -120 6 -25 5 -26 -40 -26 l-46 0 0 73 c1 70
2 73 33 92 36 23 47 64 23 85 -21 17 -20 47 2 59 23 13 74 -17 86 -51z m146
42 c5 -8 13 -50 20 -93 11 -76 11 -80 -11 -103 -43 -46 -47 -38 -75 143 -3 23
-4 49 0 58 7 20 56 16 66 -5z m244 12 c3 -5 -38 -77 -91 -160 -93 -147 -97
-152 -129 -152 -24 0 -34 5 -34 15 0 8 5 15 10 15 6 0 10 4 10 10 0 10 123
204 160 253 21 26 63 37 74 19z m139 1 c3 -5 -8 -58 -23 -119 -54 -211 -46
-194 -85 -194 -19 0 -35 5 -35 11 0 14 28 137 47 207 25 94 30 102 62 102 16
0 32 -3 34 -7z m187 -14 c0 -12 -16 -36 -35 -53 -27 -23 -39 -46 -51 -91 -14
-56 -14 -60 6 -87 24 -33 25 -42 5 -62 -22 -22 -68 -3 -90 38 -17 31 -17 36 0
102 19 76 50 125 99 155 42 25 66 25 66 -2z m123 7 c24 -19 21 -33 -12 -50
-25 -13 -31 -13 -50 0 -24 17 -27 34 -9 52 16 16 49 15 71 -2z m142 3 c4 -5
-10 -77 -31 -160 l-39 -149 -32 0 c-17 0 -34 3 -36 8 -7 10 62 284 75 299 13
16 54 17 63 2z m181 -4 c12 -29 -16 -45 -77 -45 -64 0 -80 12 -53 41 22 25
121 28 130 4z m136 -10 c-2 -11 -11 -28 -19 -37 -8 -9 -13 -27 -11 -39 2 -19
11 -25 48 -31 55 -9 94 -37 99 -69 5 -38 -28 -87 -74 -109 -54 -26 -65 -25
-65 5 0 17 8 29 25 37 53 24 36 78 -24 78 -47 0 -69 11 -87 45 -15 28 -16 37
-4 64 25 62 121 110 112 56z m123 9 c26 -20 9 -48 -32 -52 -40 -4 -59 18 -41
47 15 24 45 27 73 5z m-2888 -140 c6 -38 14 -90 17 -116 l7 -48 -39 0 -39 0
-12 69 c-6 39 -11 82 -11 97 0 29 37 77 54 71 6 -2 16 -35 23 -73z m-719 -2
c4 -28 -22 -87 -52 -120 -19 -22 -76 -52 -97 -52 -21 0 -8 50 16 66 39 25 50
52 31 73 -36 40 -11 60 69 57 23 -1 31 -6 33 -24z m1777 -3 c0 -20 -5 -24 -30
-24 -23 0 -31 5 -33 22 -2 16 3 23 20 26 35 6 43 2 43 -24z m1480 0 c0 -18 -6
-25 -25 -27 -33 -3 -52 10 -44 31 5 15 36 27 59 22 6 -1 10 -13 10 -26z
m-2641 -88 c11 -18 -6 -41 -30 -41 -17 0 -24 6 -24 18 0 10 3 22 7 25 9 10 41
8 47 -2z m700 -14 c21 -15 15 -51 -10 -61 -17 -7 -74 19 -74 34 0 8 46 39 61
40 3 0 13 -6 23 -13z m1654 1 c7 -7 12 -17 12 -24 0 -12 -39 -31 -75 -36 -29
-5 -32 32 -4 53 28 22 50 24 67 7z m436 -7 c15 -17 15 -20 -3 -42 -19 -23 -21
-23 -48 -9 -36 20 -40 44 -11 58 33 15 43 14 62 -7z m-2364 -26 c0 -24 -2 -25
-64 -25 -55 0 -64 3 -69 19 -7 28 -1 31 70 31 61 0 63 -1 63 -25z m762 19 c3
-3 4 -14 1 -25 -5 -16 -14 -19 -68 -19 -48 0 -64 4 -69 16 -3 9 -4 20 0 25 6
10 127 13 136 3z m1478 -19 c0 -26 -1 -26 -67 -23 -62 3 -68 5 -71 26 -3 21 0
22 67 22 69 0 71 -1 71 -25z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
